import React, { useState, useRef, useEffect } from "react"
import useEventListener from "../../utils/use-event-listener"
import styled from "styled-components"
import Sidemenu from "./sidemenu"
import logoHCS from "../../images/global/logos/LOGO.svg"
import menu from "../../images/global/menu/MENU.svg"

const Header = ({ data, services }) => {
  const [sidemenu, setSidemenu] = useState(false);

  const navbar = useRef(null)
  const [prevScrollPos, setPrevScrollPos] = useState("")
  const [currentScrollPos, setCurrentScrollPos] = useState("")

  useEffect(() => {
    setPrevScrollPos(window.pageYOffset)
  }, [])
  useEventListener("scroll", () => scroller())

  function scroller() {
    if (!sidemenu) {
      setCurrentScrollPos(window.pageYOffset)
      if (prevScrollPos > currentScrollPos || window.pageYOffset <= 0) {
        navbar.current.style.transform = "translateY(0)"
      } else {
        navbar.current.style.transform = "translateY(-100%)"
      }
      setPrevScrollPos(currentScrollPos)
    }
  }
  return (
    <>
      <StyledHeader ref={navbar}>
        <a href="https://hcsperformance.pt/" className="header-logo">
          <img src={logoHCS} alt="Logo HCS" />
        </a>
        <button className="sidemenuBtn" onClick={() => { setSidemenu(!sidemenu) }}>
          <img src={menu} alt="Logo HCS" />
        </button>
        <Sidemenu data={data.sidemenu} open={sidemenu} close={setSidemenu} servicesOpen={services} />
      </StyledHeader>
     
    </>
  )
}

export default Header

const StyledHeader = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 80px;
  @media only screen and (max-width: 500px){
    height: 60px;
  }
  background-color: #0f0f0f;
  display: flex;
  justify-content: space-between;
  transition: transform 300ms ease-out;
  .header-logo{
    position: absolute;
    left: 6vw;
    top: 50%;
    transform: translateY(-50%);
    width: 130px;
    @media only screen and (max-width: 500px){
      width: 100px;
    }
    img{width: 100%;}
  }
  .header-links{
    display: flex;
    align-items: center;
    color: #ffffff;
    a{
      margin: 0 1.5em;
    }
  }
  .sidemenuBtn{
    position: absolute;
    right: 6vw;
    top: 50%;
    transform: translateY(-50%);
    height: 25px;
    @media only screen and (max-width: 500px){
      height: 18px;
    }
    img{height: 100%;}
  }
`