import React, { useState, useEffect } from "react"
import { Link, navigate } from "gatsby"
import styled, { css } from "styled-components"
import Img from "gatsby-image"
import font from "../../fonts/fonts.module.scss"
import logoHCS from "../../images/global/logos/LOGO.svg"
import logoFace from "../../images/global/social/FACEBOOK.svg"
import logoInsta from "../../images/global/social/INSTAGRAM.svg"
import logoLinked from "../../images/global/social/LINKEDIN.svg"
import logoTube from "../../images/global/social/YOUTUBE.svg"

import { InView } from 'react-intersection-observer';

import { useAnimation } from 'framer-motion';

const Footer = ({ data, long, stand, floatButtons }) => {
  const [footerInView, setFooterInView] = useState(false);
  const controls = useAnimation();
  useEffect(() => {
    if (footerInView) {
      controls.start('visible');
    }
    if (!footerInView) {
      controls.start('hidden');
    }
  }, [footerInView, controls]);

  function handleClick(url, stand) {
    if (url.includes('form')) {
      const element = document.getElementById("form");
      if (element) {
        navigate(url)
      } else {
        navigate('/contactos' + url)
      }
    }

    else {
      if (stand) {
        window.open("tel:+351912091667", '_self', 'noreferrer') // stand's number
      } else {
        navigate(url)
      }
    }
  }

  return (
    <> 
    <StyledFloatButtons footerInView={footerInView ? '5em' : '2vh'}>
      {floatButtons.map((btn, index) => (
        <a onClick={() => handleClick(btn.btnLink, stand)} key={'floating-button' + index} className={index === 0 ? "master-container-svg-right" : "master-container-svg-left"}>
          <div dangerouslySetInnerHTML={{ __html: btn.btn }} className='container-svg'></div>
        </a>
      ))}
    </StyledFloatButtons>
      <InView as="div" className="container-car" threshold={0.15} onChange={inViewDiv => {
        setFooterInView(inViewDiv)
      }}>
        <StyledFooter long={long}>
          <div className="footer-links">
            {data.linksLeft.map((data, i) => (
              <Link
                to={data.link}
                className={font.oR + " small"}
                key={"header-link-" + i}
              >
                {data.linkTxt}
              </Link>
            ))}
          </div>
          <Link to="/" className="footer-logo">
            <img src={logoHCS} alt="Logo HCS" />
          </Link>
          <div className="footer-links">
            {data.linksRight.map((data, i) => (
              <Link
                to={data.link}
                className={font.oR + " small"}
                key={"header-link-" + i}
              >
                {data.linkTxt}
              </Link>
            ))}
            <div className="social-links">
              <a href="https://www.instagram.com/hcs_performance/" rel="noreferrer" target="_blank">
                <img src={logoInsta} alt="Link do Instagram" />
              </a>
              <a href="https://www.facebook.com/hcsperformance.aveiro/" rel="noreferrer" target="_blank">
                <img src={logoFace} alt="Link do Facebook" />
              </a>
              <a href="https://www.linkedin.com/company/hcsperformance" rel="noreferrer" target="_blank">
                <img src={logoLinked} alt="Link do LinkedIn" />
              </a>
              <a href="https://www.youtube.com/channel/UCiabaDlm34ELeJBah87s2LQ" rel="noreferrer" target="_blank">
                <img src={logoTube} alt="Link do Youtube" />
              </a>
              <a
                href="https://livroreclamacoes.pt/inicio"
                target="_blank"
                alt="Livro de Reclamações"
                rel="noopener noreferrer"
              >
                <div className="livroreclamacoes-container">
                  <Img
                    fluid={data.image.childImageSharp.fluid}
                    alt="Livro de Reclamações"
                  />
                </div>
              </a>
            </div>
          </div>
        </StyledFooter>
      </InView>

    </>
  )
}

export default Footer

const StyledFloatButtons = styled.div`
  position: fixed;
  bottom: ${props => props.footerInView};
  right: 0;
  z-index: 100;
  width: 21vw;
  text-align: center;
  display: inline-flex;
  margin: auto;
  transition: bottom 0.1s linear;
  .master-container-svg-right{
    text-align: right;
    margin: auto;
    margin-right: 2.5vh;
  }
  .master-container-svg-left{
    margin: auto;
    text-align: left;
    margin-left: 2.5vh;
  }
  .container-svg{
    background-color: black;
    width: 6vh;
    border-radius: 50px;
    text-align: center;
    height: 6vh;
    align-content: center;
    align-items: center;
    display: grid;
    margin: auto;
    svg{
      width: 3vh;
      height: 3vh;
    }
    :hover{
      transition: all 0.5s ease;
      background-color: #ab2c29;
      svg path{
        fill: #F2F2F2 !important;
        transition: all 0.5s ease;
      }
    }
    svg{
      margin: auto;
    }
  }
`

const StyledFooter = styled.footer`
  position: relative;
  ${props =>
    props.long &&
    css`
      position: relative;
    `}
  bottom: 0;
  left: 0;
  z-index: 101;
  width: 100%;
  background-color: #0f0f0f;
  display: flex;
  justify-content: space-between;
  padding: 1.25em 5vw;
  .footer-logo {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 130px;
    img {
      width: 100%;
    }
  }
  .footer-links {
    display: flex;
    align-items: center;
    color: #ffffff;
    max-width: 40%;
    a {
      margin: 0 1.5em;
    }
  }
  .social-links {
    display: flex;
    align-items: center;
    img {
      height: 1em;
    }
    a {
      margin: 0 0.5em;
    }
  }
  .livroreclamacoes-container {
    width: 5rem;
  }
`
