import React, { useState, useRef, useEffect } from "react"
import useEventListener from "../../utils/use-event-listener"
import { navigate } from "gatsby"
import styled from "styled-components"
import font from "../../fonts/fonts.module.scss"
import Sidemenu from "./sidemenu"
import { Buttons } from '../buttons'
import logoHCS from "../../images/global/logos/LOGO.svg"
import menu from "../../images/global/menu/MENU.svg"

const Header = ({ data, services }) => {
  const [sidemenu, setSidemenu] = useState(false)

  const navbar = useRef(null)
  const [prevScrollPos, setPrevScrollPos] = useState("")
  const [currentScrollPos, setCurrentScrollPos] = useState("")

  useEffect(() => {
    setPrevScrollPos(window.pageYOffset)
  }, [])
  useEventListener("scroll", () => scroller())



  function scroller() {
    if (!sidemenu) {
      setCurrentScrollPos(window.pageYOffset)
      if (prevScrollPos > currentScrollPos || window.pageYOffset <= 0) {
        navbar.current.style.transform = "translateY(0)"
      } else {
        navbar.current.style.transform = "translateY(-100%)"
      }
      setPrevScrollPos(currentScrollPos)
    }
  }

  return (
    <>
      <StyledHeader ref={navbar}>
        <a href="https://hcsperformance.pt/" className="header-logo">
          <img src={logoHCS} alt="Logo HCS" />
        </a>
        <div className={font.oR + ' header-links container-btn'} >
          <Buttons text={data.links.linkTxt} actionFunction={() => { navigate(data.links.link) }} btnClass='btn-black-border-red' big />
        </div>
        <button
          className="sidemenuBtn"
          onClick={() => {
            setSidemenu(!sidemenu)
          }}
        >
          <img src={menu} alt="Logo HCS" />
        </button>
        <Sidemenu
          data={data.sidemenu}
          open={sidemenu}
          close={setSidemenu}
          servicesOpen={services}
        />
      </StyledHeader>


    </>
  )
}

export default Header


const StyledHeader = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 80px;
  background-color: #0f0f0f;
  display: flex;
  justify-content: center;
  transition: transform 300ms ease-out;
  padding: 0 5vw;


  .header-logo {
    position: absolute;
    left: 5vw;
    top: 50%;
    transform: translateY(-50%);
    width: 130px;
    img {
      width: 100%;
    }
  }
  .header-links {
    height: 100%;
    a {
      margin: 0;
    }
  }
  .container-btn{
    margin: auto;
    height: 45px;
    width: 100%;
    max-width: 30vw;
  }
  .sidemenuBtn {
    position: absolute;
    right: 5vw;
    top: 50%;
    transform: translateY(-50%);
    height: 19px;
    img {
      height: 100%;
    }
    svg{
      transition: all 0.5s linear;
    }
  }

  
.btn-black-border-red{
  position: absolute;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='209.58' height='42.233' viewBox='0 0 209.58 42.233'%3E%3Cg id='Grupo_6' data-name='Grupo 6' transform='translate(-1085.423 -879.999)'%3E%3Cg id='Grupo_420' data-name='Grupo 420' transform='translate(50 -2)'%3E%3Cpath id='Caminho_252' data-name='Caminho 252' d='M274.762,981.1a6.94,6.94,0,0,0,1.309-.212,3.3,3.3,0,0,0,1.652-.971,3.066,3.066,0,0,0,.49-2.8L271.8,952.788c-1.894-7.194-10.619-11.831-22.1-11.747l-13.464.1-165.208.349.921,3.947,8.377,31.783a5.074,5.074,0,0,0,2.036,2.807,6,6,0,0,0,3.4,1.247Z' transform='translate(965.65 -58.041)' fill='none' stroke='%23ab2c29' strokeWidth='2'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
  background-position: center;
  background-size: auto;
  background-repeat: no-repeat;
  width: auto;
  text-align: center;
  padding: 0vh;
  height: 100%;
  display: inline;
  margin-left: -5vw !important;
  
  .intro-text{
      margin: 0;
  }
}
.btn-black-border-red:hover{
  .intro-text{
      color: #fff !important;
  }
}
.intro-text:hover{
color: #fff !important;
}

.intro-text{
  color: white;
  height: 100%;
  align-items: center;
  display: flex;
  letter-spacing: 0.1vw;
  padding-left: 2vh;
  padding-right: 2vh;
}



`
